import React, { useEffect } from 'react'
import { cookies } from 'scripts/cookies'
import { AppType } from 'types/App'
import { ConnectionType } from 'types/Connection'
import { navigate } from 'gatsby'
import { isOpenInLockedApp } from 'scripts/isOpenInLockedApp'
import Cookies from 'js-cookie'

interface Props {
  app: Partial<AppType>
  connection: ConnectionType
}

export const BookmarkTransfer: React.FC<Props> = ({ app, connection }) => {
  const sessionToken = cookies.get('session_token')
  const navigateToAppsByFail = (snackbarText: string) => {
    navigate('/apps', {
      state: {
        snackbarText,
      },
      replace: true,
    })
    return
  }

  useEffect(() => {
    const transfer = async () => {
      if (!(app?.id && sessionToken)) {
        navigateToAppsByFail('ログインに失敗しました')
        return
      }

      const fallbackUrl = connection.custom_data?.login_url
      if (
        typeof fallbackUrl !== 'string' ||
        (typeof fallbackUrl === 'string' && fallbackUrl === '')
      ) {
        navigateToAppsByFail(
          'アプリの設定が不足しています。LOCKEDサポート担当までご連絡ください。',
        )
        return <></>
      }

      // ネイティブアプリまたはスマホウェブの場合
      const targetUrl = connection.custom_data?.url_schema
      if (typeof targetUrl === 'string' && targetUrl !== '') {
        // ディープリンクを試みる
        location.replace(targetUrl)

        // 一定時間後にフォールバック
        setTimeout(() => {
          location.replace(fallbackUrl)
        }, 500)

        return <></>
      }

      // それ以外の場合、直接 login_url に遷移
      location.replace(fallbackUrl)
      return
    }

    transfer()
  }, [])

  return null
}
